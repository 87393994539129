<template>
  <SidePanel header="Advertiser Account Bid Types">
    <template v-slot:content>
      <p>
        Bid types info...
      </p>
      <!--<p>
        Selecting the right bid type for your account is important.
        <br /><br />Below is a detailed list of possible bid types, and the pros
        and cons for each:
      </p>

      <q-table
        flat
        bordered
        :rows="tableRows"
        :columns="tableColumns"
        row-key="type"
        hide-bottom
        wrap-cells
        table-header-class="bg-gray-very-light"
        class="q-my-md"
        style="pointer-events: none"
      >
        <template v-slot:body-cell-pros="props">
          <q-td :props="props">
            <span
              v-for="field in props.value.sort()"
              :key="props.row.type + '_' + field + '_pro'"
            >
              {{ field }} <br />
            </span>
          </q-td>
        </template>
        <template v-slot:body-cell-cons="props">
          <q-td :props="props">
            <span
              v-for="field in props.value.sort()"
              :key="props.row.type + '_' + field + '_con'"
            >
              {{ field }} <br />
            </span>
          </q-td>
        </template>
      </q-table>-->
    </template>
  </SidePanel>
</template>

<script>
import SidePanel from "@/components/UI/SidePanel";

export default {
  name: "AdvertiserAccountBidTypesInfoSidePanel",
  components: { SidePanel },
  data() {
    return {
      tableColumns: [
        {
          name: "type",
          field: "type",
          label: "Bid Type",
          required: true,
          align: "left",
          sortable: false
        },
        {
          name: "description",
          field: "description",
          label: "Description",
          required: true,
          align: "left",
          sortable: false,
          classes: "text-font-secondary"
        },
        {
          name: "pros",
          field: "pros",
          label: "Pros",
          required: true,
          align: "left",
          sortable: false,
          classes: "text-font-secondary"
        },
        {
          name: "cons",
          field: "cons",
          label: "Cons",
          required: true,
          align: "left",
          sortable: false,
          classes: "text-font-secondary"
        }
      ],
      tableRows: [
        {
          type: "Automated Bidding (Recommended)",
          description: "Some desc...",
          pros: [],
          cons: []
        },
        {
          type: "Target Bidding",
          description: "Some desc...",
          pros: [],
          cons: []
        }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
